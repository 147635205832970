<template>
    <div v-if="show">
        <div class="card">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template>{{$t('title.detailSupplier')}}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <b-button variant="warning" size="sm" class="mt-3"
                                      @click="handleBack()"
                                      v-b-tooltip.hover :title="$t('button.title.back')">
                                <i class="fa fa-arrow-left"></i> {{$t('button.back')}}
                            </b-button>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body" v-if="entity">

                <b-row>
                    <b-col lg="4" md="4" sm="12">
                        <table class="table table-striped">
                            <tbody>
                            <tr>
                                <th>{{$t('input.name')}}</th>
                                <td>{{entity.name}} | {{entity.identification_number}}</td>
                            </tr>
                            <tr>
                                <th>{{$t('input.email')}}</th>
                                <td>{{entity.email}}</td>
                            </tr>
                            <tr>
                                <th>{{$t('input.phone')}}</th>
                                <td>{{entity.phone}}</td>
                            </tr>
                            <tr>
                                <th>{{$t('input.fax')}}</th>
                                <td>{{entity.fax}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col><!-- /.col -->
                    <b-col lg="4" md="4" sm="12">
                        <table class="table table-striped">
                            <tbody>
                            <tr>
                                <th>{{$t('input.mainLocation')}}</th>
                                <td>
                                    {{entity.main_location.street}},
                                    {{entity.main_location.street_no}},
                                    {{entity.main_location.zip}},
                                    {{entity.main_location.city}},
                                    {{entity.main_location.country}}
                                    <div v-if="entity.location_code">
                                        <l-code-clipboard :label="entity.location_code"/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{{$t('input.supplierTypes')}}</th>
                                <td>{{entity.supplier_user_types.map((item) => item.title).join(', ') }}</td>
                            </tr>
                            <tr>
                                <th>{{$t('input.logisticTypes')}}</th>
                                <td>{{entity.supplier_logistic_types.map((item) => item.title).join(', ') }}</td>
                            </tr>
                            <tr>
                                <th>{{$t('input.comment')}}</th>
                                <td>{{entity.comments}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col><!-- /.col -->
                    <b-col lg="4" md="4" sm="12">
                        <span class="font-size-18 font-weight-bold text-capitalize mb-2">{{$t('title.attachments')}}</span>
                        <table class="table table-striped table-sm">
                            <tbody>
                            <tr v-for="(cd, index) in entity.supplier_documents">
                                <td width="50">
                                    <b-button :title="$t('button.download')"
                                              v-b-tooltip.hover
                                              variant="outline-primary"
                                              class="ml-2 ml-2 btn-sm"
                                              v-if="cd.document && cd.document.download_url"
                                              :href="cd.document.download_url"
                                              target="_blank">
                                        <i class="fa fa-cloud-download"></i>
                                    </b-button>
                                </td>
                                <th>{{cd.title}}</th>
                            </tr>
                            </tbody>
                            <tfoot v-show="entity.supplier_documents.length <= 0">
                            <tr>
                                <th colspan="2">{{$t('title.noDataAvailable')}}</th>
                            </tr>
                            </tfoot>
                        </table>
                    </b-col><!-- /.col -->
                </b-row><!-- /.row -->
                <b-row>
                    <b-col sm="12"> <hr> </b-col>
                    <b-col lg="6" sm="12">
                        <span class="font-size-18 font-weight-bold text-capitalize mb-4">
                            {{$t('title.additionalLocations')}}
                        </span>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('input.location')}}</th>
                                    <th>{{$t('input.locationType')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cl, index) in entity.supplier_locations">
                                    <td>{{index + 1}}</td>
                                    <td>
                                        {{cl.location.street}},
                                        {{cl.location.street_no}},
                                        {{cl.location.zip}},
                                        {{cl.location.city}},
                                        {{cl.location.country}}
                                        <div v-if="cl.location_code">
                                            {{$t('title.locationCode')}}:
                                            <l-code-clipboard :label="cl.location_code"/>
                                        </div>
                                    </td>
                                    <td>{{cl.location_type.title}}</td>
                                </tr>
                            </tbody>
                            <tfoot v-show="entity.supplier_locations.length <= 0">
                                <tr>
                                    <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </b-col><!-- /.col -->
                    <b-col lg="6" sm="12">
                        <span class="font-size-18 font-weight-bold text-capitalize mb-4">
                            {{$t('title.contacts')}}
                        </span>
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('input.contact')}}</th>
                                <th>{{$t('input.locations')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(cc, index) in entity.supplier_contacts">
                                <td>{{index + 1}}</td>
                                <td>
                                    <div>{{cc.contact.name}}</div>
                                    <div>{{cc.contact.email}}</div>
                                    <div>{{cc.contact.mobile}}</div>
                                    <div>{{cc.contact.functions}}</div>
                                </td>
                                <td>
                                    <div v-for="(cl, i) in cc.locations">
                                        {{i + 1}}. {{cl.street_no}}, {{cl.street}}, {{cl.zip}}, {{cl.city}}, {{cl.country}}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot v-show="entity.supplier_contacts.length <= 0">
                            <tr>
                                <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                            </tr>
                            </tfoot>
                        </table>
                    </b-col><!-- /.col -->
                    <b-col lg="6" sm="12">
                        <span class="font-size-18 font-weight-bold text-capitalize mb-4">
                            {{$t('title.contracts')}}
                        </span>
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('input.title')}}</th>
                                <th>{{$t('input.duration')}}</th>
                                <th width="100px">{{$t('input.startDate')}}</th>
                                <th width="100px">{{$t('input.endDate')}}</th>
                                <th>{{$t('input.description')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(cb, index) in entity.supplier_contracts">
                                <td>{{index + 1}}</td>
                                <td>{{cb.title}}</td>
                                <td>{{cb.duration}}</td>
                                <td>{{cb.start_date}}</td>
                                <td>{{cb.end_date}}</td>
                                <td>{{cb.description}}</td>
                            </tr>
                            </tbody>
                            <tfoot v-show="entity.supplier_contracts.length <= 0">
                            <tr>
                                <th colspan="6">{{$t('title.noDataAvailable')}}</th>
                            </tr>
                            </tfoot>
                        </table>
                    </b-col><!-- /.col -->
                    <b-col lg="6" sm="12" v-show="_.findIndex(entity.supplier_user_types, {id: 3}) >= 0">
                        <span class="font-size-18 font-weight-bold text-capitalize mb-4">
                            {{$t('title.additionalServices')}}
                        </span>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('input.title')}}</th>
                                    <th>{{$t('input.description')}}</th>
                                    <th>{{$t('input.type')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cb, index) in entity.supplier_additional_services">
                                    <td>{{index + 1}}</td>
                                    <td>{{cb.title}}</td>
                                    <td>{{cb.description}}</td>
                                    <td>{{ [_.find(dropdowns.additional_service_types, {id: cb.additional_service_type_id})].map((item) => item.label).join(', ') }}</td>
                                </tr>
                            </tbody>
                            <tfoot v-show="entity.supplier_additional_services.length <= 0">
                                <tr>
                                    <th colspan="4">{{$t('title.noDataAvailable')}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </b-col><!-- /.col -->
                </b-row><!-- /.row -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
    </div>
</template>
<script>
    import Error from '../../../util/Error'
    import {request} from '../../../util/Request'

    export default {
        props: ['id', 'handleBack'],
        data() {
            return {
                entity: null,
                show: true,
                dropdowns: {
                    additional_service_types: []
                },
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getAdditionalServiceTypes();
                this.getDetails(this.$route.query.oToken);
            }
        },
        methods: {
            async getDetails(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/suppliers/client/detail/${id}`,
                    })

                    const {data} = response
                    this.entity = data;
                } catch (error) {
                    this.handleServerError(error)
                }
            },
            async getAdditionalServiceTypes() {
                try {
                    const response = await request({
                        url: '/dropdowns/additional/service/types',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.additional_service_types = data

                } catch (e) {
                    this.dropdowns.additional_service_types = []
                }
            },
        }
    }
</script>
